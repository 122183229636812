<template>
  <Sidebar ref="sidebar" :title="$tuf('residents')" @close="onSidebarClosed">
    <form @submit.prevent="save" class="mb-10 -m-5 border-b border-gray-300 bg-white rounded p-5">

      <Overlay v-if="loading" class="z-2 mt-16 flex justify-center items-center bg-white">
        <div class="bg-white shadow rounded w-8 h-8 flex justify-center items-center text-prasset-green-500">
          <LoadingIndicator />
        </div>
      </Overlay>

      <div class="text-sm text-prasset-green-800 mb-2">{{ $tuf('asset') }}</div>
      <Address v-if="asset && asset.address" :address="asset.address" class="mb-4" />

      <FieldsGenerator
        v-if="!reinitialize"
        :errors="errors"
        :fields="fields"
        :data="form"
        :entryId="form.id"
        entryType="contract"
        :fileCollection="form.media"
        class="relative z-1"
      />

      <div class="flex">
        <button
          type="button"
          class="button button--outlined relative mr-4"
          :class="{ 'opacity-25': loading && submittedBy !== 'save' }"
          :disabled="loading"
          @click="save(false, 'save')"
        >
          <span :class="{ 'invisible': loading && submittedBy === 'save' }">
            {{ $tuf('add_contract') }}
          </span>
          <span v-if="loading && submittedBy === 'save'" class="absolute inset-0 flex justify-center items-center">
            <LoadingIndicator />
          </span>
        </button>
      </div>
    </form>

    <div class="grid grid-cols-1 gap-4">
      <Contract v-for="contract in contracts" :contract="contract" :key="contract.id" @change="markAsChanged" />
    </div>
  </Sidebar>
</template>

<script>
import { toRefs, reactive, onMounted, nextTick } from '@vue/composition-api';
import { generateUUID } from '@/providers/helpers';
import Address from '@/components/Address';
import Contract from '@/modules/core/views/components/Contract';
import EventBus from '@/eventbus';
import FieldsGenerator from '@/modules/core/views/components/FieldsGenerator';
import LoadingIndicator from '@/components/LoadingIndicator';
import Overlay from '@/components/Overlay';
import Sidebar from '@/modules/core/views/components/Sidebar';
import useAssets from '@/compositions/useAssets';
import useChangedState from '@/compositions/useChangedState';
import useContract from '@/compositions/useContracts';

export default {
  name: 'Contracts',

  components: {
    Address,
    Contract,
    FieldsGenerator,
    LoadingIndicator,
    Overlay,
    Sidebar,
  },

  setup(props, { root, refs }) {
    const { project_id, entry } = root.$route.params;
    const { changed, watchChanges, commitChanges } = useChangedState();
    const { fetchContracts, createContract } = useContract();
    const { fetchAsset } = useAssets();

    const state = reactive({
      form: {
        id: generateUUID(),
        status: 'IS_CREATED',
      },
      errors: {},
      ready: false,
      changed,
      loading: false,
      submittedBy: null,
      changedContract: false,
      contracts: [],
      asset: {},
      reinitialize: false, // FIXME: temp fix for clearing fileuploads
    });

    /**
     * Load form data.
     */
    async function load() {
      state.loading = true;

      state.asset = await fetchAsset(project_id, entry);

      state.contracts = await fetchContracts(project_id, {
        'filter[asset]': entry,
        'sort': '-start_date',
      });

      state.loading = false;
      state.ready = true;

      watchChanges(state, 'form');
    }

    /**
     * Save form data.
     */
    async function save(close = true, submittedBy = 'save-and-back') {
      state.submittedBy = submittedBy;
      state.loading = true;
      state.errors = {};

      try {
        await createContract(project_id, {
          ...state.form,
          asset: state.asset.id,
          // person_id: state.form.person,
        });

        state.form = {
          id: generateUUID(),
          status: 'IS_CREATED',
          start_date: '',
          end_date: '',
        };

        state.reinitialize = true;
        await nextTick();
        state.reinitialize = false;

        root.$notify({ type: 'success', title: root.$tuf('success'), text: `Contract is aangemaakt` });

        state.loading = false;

        commitChanges(state, 'form');

        state.contracts = await fetchContracts(project_id, {
          'filter[asset]': entry,
          'sort': '-start_date',
        });

        // Notify table to update the records.
        EventBus.$emit('record:updated');

        if (close) {
          refs.sidebar.close();
        }
      } catch (error) {
        state.loading = false;
        state.errors = error.response.data.errors;
      }
    }

    function onSidebarClosed() {
      if (state.changedContract) {
        // Notify table to update the records, so we see the occupant in the table
        EventBus.$emit('record:updated');
      }
    }

    function markAsChanged() {
      state.changedContract = true;
    }

    onMounted(() => {
      load();
    });

    return {
      ...toRefs(state),
      save,
      markAsChanged,
      onSidebarClosed,
      fields: [
        {
          label: 'Persoon',
          name: 'person',
          map: 'person.id',
          type: 'singleselect',
          endpoint: 'people',
          colspan: 6,
          placeholder: 'Zoek of selecteer een persoon',
        },
        {
          label: 'Status',
          name: 'status',
          map: 'status',
          type: 'select',
          options: {
            IS_CREATED: 'nieuw contract',
            IS_ACTIVE: 'lopend contract',
            IS_ENDED: 'beëindigd contract',
          },
          colspan: 2,
        },
        {
          label: 'Start datum',
          name: 'start_date',
          map: 'start_date',
          type: 'date',
          colspan: 2,
        },
        {
          label: 'Eind datum',
          name: 'end_date',
          map: 'end_date',
          type: 'date',
          colspan: 2,
        },
        {
          label: 'Overeenkomsten en/of contracten',
          name: 'files',
          map: 'media',
          type: 'files',
          colspan: 6,
          config: {
            confidential: true,
            acceptedCategories: ['images', 'documents'],
          },
        },
      ],
    };
  },
};
</script>
