import Api from '@/providers/api';

/**
 * Fetch an asset from api.
 */
async function fetchAsset(project_id, asset_id) {
  const response = await Api().get(`projects/${project_id}/assets/${asset_id}`);
  return response.data.data;
}

export default function useAssets() {
  return {
    fetchAsset,
  };
}
